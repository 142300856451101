import debounce from 'lodash.debounce';
import {
    getElementOrParentBySelector,
    removeClassFromAll,
} from '../helper/dom-helpers';

const searchInputSelector = '[data-search-input]';
const searchPopupSelector = '[data-search-popup]';
const searchTooltipSelector = '[data-search-content]';
const activeClass = 'is-active';
const loadingClass = 'is-loading';
const mobileSearchTriggerSelector = '.js-search-mobile-popup';
const mobileCloseSelector = '.js-header-search-mobile-close'; 
const mobileBreakpoint = 991;

class SearchPopup {
    constructor(el) {
        if (!this.set(el)) return;
        this.isMobileTriggered = false;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.search = '';
        this.popup = document.querySelector(searchPopupSelector);
        this.content = this.popup.querySelector(searchTooltipSelector);

        return true;
    }

    init() {
        this.setSearchInput();
        if (window.innerWidth > mobileBreakpoint) {
            this.initClickOutside();
        } else {
            this.initMobileClose();
        }
        this.initMobilePopup();
    }

    setSearchInput() {
        if (!this.root) return;

        this.filtersInputChange = debounce(this.searchInput, 500);
        this.root.addEventListener('input', event => {
            if (this.root.value === '' && !this.isMobileTriggered) {
                this.popup.classList.remove(activeClass);
                this.content.classList.add(loadingClass);
                this.search = '';
            } else if (this.root.value === '' && this.isMobileTriggered) {
                this.popup.classList.add(activeClass);
                this.content.classList.remove(loadingClass);
                this.search = '';
            } else {
                this.popup.classList.add(activeClass);
                this.content.classList.add(loadingClass);
                this.filtersInputChange(this.root);
                this.search = this.root.value;
            }
        });

        this.root.addEventListener('focus', event => {
            if (this.search !== '') {
                this.popup.classList.add(activeClass);
            }
        });
    }

    initClickOutside() {
        let elem = this.root;
        document.addEventListener('click', (event) => {
            let check = getElementOrParentBySelector(
                event,
                searchTooltipSelector
            );
            if (!check) {
                check = getElementOrParentBySelector(
                    event,
                    searchInputSelector
                );
            }
            if (!check) {
                check = getElementOrParentBySelector(
                    event,
                    mobileSearchTriggerSelector
                );
            }

            if (!check) {
                removeClassFromAll(searchPopupSelector, activeClass);
                document.querySelector('body').classList.remove('-hidden');
            }
        });
    }

    initMobilePopup() {
        const mobileTrigger = document.querySelector(mobileSearchTriggerSelector);
        if (mobileTrigger && window.innerWidth <= mobileBreakpoint) {
            mobileTrigger.addEventListener('click', () => {
                document.querySelector('body').classList.add('-hidden');
                this.popup.classList.add(activeClass);
                this.content.classList.remove(loadingClass);
                this.isMobileTriggered = true;
            });
        }
    }

    initMobileClose() {
        const mobileCloseBtn = document.querySelector(mobileCloseSelector);
        if (mobileCloseBtn && window.innerWidth <= mobileBreakpoint) {
            mobileCloseBtn.addEventListener('click', () => {
                this.popup.classList.remove(activeClass);
                document.querySelector('body').classList.remove('-hidden');
                this.isMobileTriggered = false; 
            });
        }
    }

    async searchInput(item) {
        if (!item.value) return;

        this.search = item.value;
        this.getSearchData();
    }

    async getSearchData() {
        const formData = new FormData();
        if (this.search) {
            formData.append('searched', this.search);
        }

        const request = await fetch(
            `${home_url}/wp-json/mda-rest-api-frontend/search/get`,
            {
                method: 'POST',
                body: formData,
            }
        );
        const res = await request.json();

        if (res) {
            this.content.classList.remove(loadingClass);
            this.content.innerHTML = res.view;
        }
    }
}

function initSearchPopup() {
    const sections = document.querySelectorAll(searchInputSelector);
    if (!sections) return;

    sections.forEach(item => new SearchPopup(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initSearchPopup();
} else {
    document.addEventListener('DOMContentLoaded', initSearchPopup);
}
